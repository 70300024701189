import Layout from '@/components/Layout/Layout';
import SEO from '@/components/SEO/Seo';
import SearchWidgetContainer from '@/components/SearchWidgetContainer';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import { graphql } from 'gatsby';
import React from 'react';
import parse from 'react-html-parser';

import Utils from './sharedUtils';
import './style.scss';

interface Props {
  pageContext: {
    brandName: string;
    brandImage: string;
    carModel: {
      id: string;
      name: string;
      imageUrl: string;
      useModelDescription: boolean;
      description: string;
    };
  };
  data: {
    strapi: {
      carModelStatic: {
        description: {
          textBlock: string;
        };
        bottomParagraph: {
          textBlock: string;
        };
      };
      carManufacturer: {
        HeroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerText: string;
          mainImg: {
            url: string;
            alternativeText: string;
          };
          headerPrefix: string;
          lozengeText: string;
          iconImg: {
            url: string;
          };
        };
      };
    };
  };
}

const individualCarModelPage = ({ data, pageContext }: Props) => {
  const { brandName, brandImage, carModel } = pageContext;
  var { description, bottomParagraph } = data.strapi.carModelStatic;
  var { HeroSection } = data.strapi.carManufacturer;

  const tags = {
    '[car-brand]': Utils.capitalize(brandName),
    '[car-model]': Utils.capitalize(carModel.name),
  };

  if (HeroSection) {
    // Replace tags in herosection
    if (HeroSection.headerText)
      HeroSection.headerText = Utils.fillTags(HeroSection.headerText, tags);

    if (HeroSection.lozengeText)
      HeroSection.lozengeText = Utils.fillTags(HeroSection.lozengeText, tags);
  }

  // Replace tags into content
  if (!carModel.useModelDescription)
    description.textBlock = Utils.fillTags(description.textBlock, tags);
  bottomParagraph.textBlock = Utils.fillTags(bottomParagraph.textBlock, tags);

  return (
    <>
      <SEO
        title={`${carModel.name} Tyres`}
        description={`Choose tires for your ${carModel.name}`}
        image={brandImage}
      />
      <Layout>
        <GeneralHeroSec data={HeroSection} />
        <div className="blockImageInfo columnOnly">
          {carModel.imageUrl ? (
            <img
              className="image big"
              src={carModel.imageUrl}
              alt={`${`${brandName} ${carModel.name}`} image`}
            />
          ) : null}
          <h2 className="info nopad">
            {Utils.capitalize(`${brandName} ${carModel.name}`)}
          </h2>
        </div>
        <div className="infoBlock">
          <div className="center">
            {parse(
              carModel.useModelDescription
                ? carModel.description
                : description.textBlock,
            )}
          </div>
        </div>
        <SearchWidgetContainer />
        <div className="infoBlock">
          <div className="center">{parse(bottomParagraph.textBlock)}</div>
        </div>
      </Layout>
    </>
  );
};

export const carModelStatic = graphql`
  query($brandId: ID!) {
    strapi {
      carModelStatic {
        description {
          textBlock
        }
        bottomParagraph {
          textBlock
        }
      }
      carManufacturer(id: $brandId, publicationState: PREVIEW) {
        HeroSection {
          activeLozenge
          className
          displayIcon
          headerPrefix
          headerText
          iconImg {
            url
            alternativeText
          }
          lozengeText
          mainImg {
            url
            alternativeText
          }
        }
      }
    }
  }
`;

export default individualCarModelPage;
